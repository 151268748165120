import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import GlobalStyle from './components/GlobalStyle';
import './assets/css/main.css';
import ContextProvider from './contexts/ContextProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ContextProvider>
            <GlobalStyle>
                <App />
            </GlobalStyle>
        </ContextProvider>
    </React.StrictMode>,
);
