import classNames from 'classnames/bind';

import logo from '../../assets/images/logo_lagi_8_resize.jfif';
import logoEbay from '../../assets/images/ebay_logo.png';
import { Link } from 'react-router-dom';
import styles from './System.module.scss';
const cx = classNames.bind(styles);

function System() {
    return (
        <div className={cx('system')}>
            <h3 className={cx('title')}>Hệ thống COSTCO</h3>
            <h3 className={cx('sub-title')}>Tìm hiểu về hệ thống COSTCO</h3>
            <div className={cx('banner')}>
                <div className={cx('banner-item')}>
                    <div className={cx('banner-inner')}>
                        <div className={cx('banner-inner-logo')}>
                            <img src={logoEbay} className={cx('banner-logo')} alt="" />
                        </div>
                        <div className={cx('banner-content')}>
                            <div className={cx('banner-title')}>Giới thiệu về hệ thống</div>
                            <h3 className={cx('banner-company')}>COSTCO</h3>
                        </div>
                    </div>
                </div>
                <div className={cx('banner-item')}>
                    <div className={cx('banner-inner')}>
                        <div className={cx('banner-inner-logo')}>
                            <img src={logoEbay} className={cx('banner-logo')} alt="" />
                        </div>
                        <div className={cx('banner-content')}>
                            <div className={cx('banner-title')}>Thành viên thân thiết</div>
                            <h3 className={cx('banner-company')}> COSTCO</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className={cx('infomation')}>
                <Link to="/system/support" className={cx('infomation-item')}>
                    HỒ SƠ CÔNG TY
                </Link>
                <Link to="/system/termsofservice" className={cx('infomation-item')}>
                    QUY TẮC VÀ ĐIỀU KHOẢN
                </Link>
                <Link to="/system/invest" className={cx('infomation-item')}>
                    ĐẦU TƯ VÀ THU NHẬP
                </Link>
                <Link to="/system/condition" className={cx('infomation-item')}>
                    ĐIỀU KIỆN MỞ TÀI KHOẢN:
                </Link>
                {/* <Link to="/system/support" className={cx('infomation-item')}>
                    Hoạt động khuyến mãi
                </Link>
                <Link to="/system/support" className={cx('infomation-item')}>
                    Điều kiện mở tài khoản
                </Link>
                <Link to="/system/support" className={cx('infomation-item')}>
                    Điều kiện & Điều khoản
                </Link>
                <Link to="/system/support" className={cx('infomation-item')}>
                    Quyền riêng tư và bảo mật
                </Link> */}
            </div>
        </div>
    );
}

export default System;
