import classNames from 'classnames/bind';
import styles from './Home.module.scss';
import Mission from '../../components/Mission';

import Slider from '../../components/Slider';
import { Link } from 'react-router-dom';
import ListHome from './ListHome';
import { useEffect, useState } from 'react';
import SETTINGS from '../../setting.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import './Home.css';
import naptienicon from '../../assets/images/naptienicon.png';
import ruttienicon from '../../assets/images/ruttienicon.png';
import dautuicon from '../../assets/images/dautuicon.png';
import cskhicon from '../../assets/images/cskhicon.png';
import thongbaoicon from '../../assets/images/thongbaoicon.png';
import banner1 from '../../assets/images/banner-home1.png';
import banner2 from '../../assets/images/banner-home2.png';
import banner3 from '../../assets/images/banner33.png';

const axios = require('axios').default;

const cx = classNames.bind(styles);

function formatMoney(money = 0) {
    return String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}
const formatter = new Intl.NumberFormat('en-us', {
    style: 'currency',
    currency: 'USD',
});

const checkToken = () => {
    let accessToken = localStorage.getItem('auth');
    if (!accessToken) {
        localStorage.removeItem('auth');
        window.location.href = '/account/login';
    } else {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/me`, {
                headers: {
                    'x-access-token': accessToken,
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'error') {
                    localStorage.removeItem('auth');
                    window.location.href = '/account/login';
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};
function Home() {
    let [user, setUser] = useState({
        data: [{ money: 0, dongbangtk: 0 }],
        moneyEarn: { moneyEarnToday: 0, moneyEarnPreDay: 0 },
    });

    const [notiModal, setNotiModal] = useState(false);
    const [noti, setNoti] = useState([]);

    useEffect(() => {
        checkToken();
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/userInfo`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    setUser(data);
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/getnoti`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let { status, noti } = response.data;
                if (status) {
                    setNoti(noti);
                    if (noti[0].notification.length > 0 || noti.length > 1) {
                        setNotiModal(true);
                    }
                } else {
                    toast.warn(response.data.msg, { theme: 'light' });
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    }, []);

    const handleNotiModal = () => {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/getnoti`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let { status, noti } = response.data;
                if (status) {
                    setNoti(noti);
                    if (noti[0].notification.length > 0 || noti.length > 1) {
                        setNotiModal(true);
                    }
                } else {
                    toast.warn(response.data.msg, { theme: 'light' });
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    };

    return (
        <div id="Home" className="relative">
            <section>
                <Slider />
            </section>

            <div className="textAnimate">
                <FontAwesomeIcon className="fa-arrow-left" icon={faBullhorn} />
                <div className="wrapper">
                    <p className="target">
                        Chương trình khuyến mãi "Gian hàng ghép đôi" thưởng ngay $66 cho mỗi cặp đôi khi đăng ký và mở
                        thành công gian hàng ghép đôi. Vui lòng liên hệ dịch vụ CSKH để tìm hiểu thêm!
                    </p>
                </div>
            </div>

            <div className="ant-row ant-row-space-around">
                <div className="ant-col ant-col-4 sc-iJKOTD gObbco">
                    <a href="https://tawk.to/chat/641bff3f31ebfa0fe7f42f6f/1gs6lq4hv" target="_blank">
                        <div className="main-action">
                            <img src={naptienicon} alt="Nạp tiền" /> Nạp tiền
                        </div>
                    </a>
                </div>
                <div className="ant-col ant-col-4 sc-iJKOTD gObbco">
                    <Link to="/withdraw">
                        <div className="main-action">
                            <img src={ruttienicon} alt="Rút tiền" /> Rút tiền
                        </div>
                    </Link>
                </div>
                <div className="ant-col ant-col-4 sc-iJKOTD gObbco">
                    <Link to="/my/guide">
                        <div className="main-action">
                            <img src={dautuicon} alt="Đầu tư" /> Đầu tư
                        </div>
                    </Link>
                </div>
                <div className="ant-col ant-col-4 sc-iJKOTD gObbco">
                    <a href="https://tawk.to/chat/641bff3f31ebfa0fe7f42f6f/1gs6lq4hv" target="_blank">
                        <div className="main-action">
                            <img src={cskhicon} alt="CSKH" /> CSKH
                        </div>
                    </a>
                </div>
                <div onClick={handleNotiModal} className="ant-col ant-col-4 sc-iJKOTD gObbco">
                    <div className="main-action">
                        <img src={thongbaoicon} alt="Thông báo" /> Thông báo
                    </div>
                </div>
            </div>

            <div className="ant-row ant-row-center">
                <div className="ant-col">
                    <div className="sc-ikJyIC gNkWet">
                        <div className="sc-jJoQJp hzrkvO">Số dư tài khoản</div>
                        <div className="sc-hiCibw iYoREV" style={{ color: 'rgb(231, 233, 236)' }}>
                            {formatter.format(user.data[0].money)}
                        </div>
                        <div className="sc-jJoQJp hzrkvO">Thu nhập hôm nay</div>
                        <div className="sc-hiCibw iYoREV" style={{ color: 'rgb(82, 164, 120)' }}>
                            {formatter.format(user.moneyEarn.moneyEarnToday)}
                        </div>
                    </div>
                </div>

                <div className="ant-col">
                    <div className="sc-ikJyIC gNkWet">
                        <div className="sc-jJoQJp hzrkvO">Số dư đang xử lý</div>
                        <div className="sc-hiCibw iYoREV" style={{ color: 'rgb(183, 62, 82)' }}>
                            {formatter.format(user.data[0].dongbangtk)}
                        </div>
                        <div className="sc-jJoQJp hzrkvO">Thu nhập hôm qua</div>
                        <div className="sc-hiCibw iYoREV" style={{ color: 'rgb(160, 73, 211)' }}>
                            {formatter.format(user.moneyEarn.moneyEarnPreDay)}
                        </div>
                    </div>
                </div>
            </div>

            <main>
                <div className="sc-gWXbKe fhBEMg">
                    <div className="banner-middle-1">
                        <div className="banner-1">
                            <img src={banner1} alt="Banner" />
                        </div>
                        <div className="banner-1">
                            <img src={banner2} alt="Banner" />
                        </div>
                    </div>
                    <div className="banner-middle-2 py-2">
                        <img src={banner3} alt="Banner" />
                    </div>
                </div>
                {/* <div className="flex justify-between">
                    <Link to="/recharge" className="w-full py-2 pr-2">
                        <div
                            className="flex-1 p-5 text-center text-[#fff] text-3xl lg:text-4xl"
                            style={{
                                borderRadius: '4px',
                                background: `linear-gradient(to right, #00CDAC, #02AAB0)`,
                            }}
                        >
                            nạp tiền
                        </div>
                    </Link>
                    <Link to="/withdraw" className="w-full py-2 pl-2">
                        <div
                            className="flex-1 p-5 text-center text-[#fff] text-3xl lg:text-4xl"
                            style={{
                                borderRadius: '6px',
                                background: `linear-gradient(to right, #ec008c, #fc6767)`,
                            }}
                        >
                            rút tiền
                        </div>
                    </Link>
                </div> */}
                <div className=" rounded-[.16rem] my-4">
                    <div className="thu-thap-dai-ly">
                        <p>THU THẬP ĐẠI LÝ</p>
                    </div>
                    <div className="box-home-shadow">
                        <ListHome amount={5} />
                    </div>
                </div>
            </main>

            {notiModal && (
                <div className="absolute top-[-64px] bg-gray-700/75 h-[100dvh] w-full flex items-center justify-center z-[1000000000000000]">
                    <div className="bg-gray-800 relative w-11/12 flex flex-col items-center rounded-md overflow-auto max-h-[80dvh]">
                        <span
                            className="absolute top-0 right-0 text-white text-4xl p-4 select-none"
                            onClick={() => setNotiModal(false)}
                        >
                            X
                        </span>
                        <h3 className="text-white py-4 text-5xl font-bold">Thông báo</h3>
                        {noti.length === 1 && noti[0].notification.length === 0 ? (
                            <sesstion className="w-full px-4 my-2">
                                <div className="px-4 py-2 rounded-md text-white text-md bg-gray-700">
                                    <p className="text-center">Hiện chưa có thông báo!</p>
                                </div>
                            </sesstion>
                        ) : (
                            noti.map((item, index) => {
                                if (item.notification.length === 0) {
                                    return;
                                }

                                if (index === 0) {
                                    return (
                                        <sesstion key={index} className="w-full px-4 my-2">
                                            <div className="px-4 py-2 rounded-md text-white text-md bg-gray-700">
                                                <p className="text-center text-bold text-3xl">
                                                    Thông báo từ ban quản trị
                                                </p>
                                                <p className="text-justify">{item.notification}</p>
                                                <p className="w-full text-right">{item.create_at}</p>
                                            </div>
                                        </sesstion>
                                    );
                                }

                                return (
                                    <sesstion key={index} className="w-full px-4 my-2">
                                        <div className="px-4 py-2 rounded-md text-white text-md bg-gray-700">
                                            <p className="text-justify">{item.notification}</p>
                                            <p className="w-full text-right">{item.create_at}</p>
                                        </div>
                                    </sesstion>
                                );
                            })
                        )}
                        <button
                            onClick={() => setNotiModal(false)}
                            className="bg-blue-500 text-white px-4 py-2 rounded-md my-4"
                        >
                            Đóng
                        </button>
                    </div>
                </div>
            )}

            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default Home;
