import styles from './Order.module.scss';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import SETTINGS from '../../setting.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import banner3 from '../../assets/images/ebay-banner2.jpg';
import './Order.css';

import audio from '../../assets/audio/hongbao.mp3';
import { useStateContext } from '../../contexts/ContextProvider';
const axios = require('axios').default;

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

const formatter = new Intl.NumberFormat('en-us', {
    style: 'currency',
    currency: 'USD',
});

const normalFormatter = new Intl.NumberFormat();

const checkToken = () => {
    let accessToken = localStorage.getItem('auth');
    if (!accessToken) {
        localStorage.removeItem('auth');
        window.location.href = '/account/login';
    } else {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/me`, {
                headers: {
                    'x-access-token': accessToken,
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'error') {
                    localStorage.removeItem('auth');
                    window.location.href = '/account/login';
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};

function Order() {
    const { setReload } = useStateContext();
    const { id_mission } = useParams();
    const newId = useRef(id_mission);
    let [box, setBox] = useState(false);
    let [update, setUpdate] = useState(false);
    let [go1, setGo1] = useState(false);
    let [go2, setGo2] = useState(false);
    let [width, setWidth] = useState(0);
    let [myMission, setMyMission] = useState([{}]);
    const [level, setLevel] = useState([]);

    const [progress, setProgress] = useState(0);
    const [user, setUser] = useState({
        data: [{ money: 0, dongbangtk: 0, roses: 0 }],
        moneyEarn: { moneyEarnPreDay: 0, moneyEarnToday: 0 },
        mission: {
            amount: 0,
            result: 0,
            amountToday: 0,
        },
    });

    const navigate = useNavigate();

    const playAudio = () => {
        new Audio(audio).play();
    };

    const handAnimateMission = async () => {
        await sleep(200);
        setWidth(10);
        await sleep(500);
        setWidth(30);
        await sleep(1500);
        setWidth(80);
        await sleep(600);
        setWidth(100);
        await sleep(0);
        setGo1(true);
        await sleep(0);
        setGo2(true);
        await sleep(0);
        handSendMission();
    };

    const handSendMission = async () => {
        const headers = {
            'x-access-token': localStorage.getItem('auth'),
            'Access-Control-Allow-Origin': '*',
        };
        axios
            .post(
                `${SETTINGS.BASE_URL}/api/webapi/mission/new`,
                { level: id_mission },
                {
                    headers,
                },
            )
            .then(async function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    setBox(false);
                    setGo1(false);
                    setGo2(false);
                    setWidth(0);
                    if (data.data.type === 1) {
                        toast.success('Đã tìm thấy 1 đơn hàng!', { theme: 'light' });
                        setReload((pre) => !pre);
                        await sleep(1000);
                        setTimeout(() => {
                            navigate(`/order/confirm/${data.data.mission.id_mission}`);
                        }, 1000);
                        return;
                    }
                    if (data.data.type === 2) {
                        toast.warn('Hiện chưa có sản phẩm mới !', { theme: 'light' });
                        setTimeout(() => {
                            window.location.reload();
                            // navigate(`/order/mission/${id_mission}`);
                        }, 1000);
                        return;
                    }
                    if (data.data.type === 1000) {
                        toast.success('Bạn đã hoàn thành đủ đơn hàng trong ngày', { theme: 'light' });
                        setTimeout(() => {
                            window.location.reload();
                            // navigate(`/order/mission/${id_mission}`);
                        }, 1000);
                        return;
                    }
                    if (data.data.type === 1001) {
                        toast.warn(data.data.msg, { theme: 'light' });
                        return;
                    }
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    };

    useEffect(() => {
        checkToken();
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/userInfo`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    setUser(data);
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (progress < 100) {
                setProgress(progress + 1);
            }
        }, 80);

        return () => clearInterval(intervalId);
    }, [progress]);

    useEffect(() => {
        checkToken();

        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/mission/my`, {
                params: {
                    id_mission: newId.current,
                },
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    setMyMission(data.data);
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/level/list`, {
                params: {
                    id_mission: newId.current,
                },
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    setLevel(data.data.levelList);
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    }, []);

    const upgradeMember = async () => {
        const headers = {
            'x-access-token': localStorage.getItem('auth'),
            'Access-Control-Allow-Origin': '*',
        };
        axios
            .post(
                `${SETTINGS.BASE_URL}/api/webapi/user/upgrade`,
                { level: id_mission },
                {
                    headers,
                },
            )
            .then(async function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    setUpdate(false);
                    if (data.data.type === 1) {
                        toast.success('Nâng cấp gói thành công', { theme: 'light' });
                        playAudio();
                        setTimeout(() => {
                            window.location.reload();
                        }, 1200);
                    } else {
                        toast.warn('Số dư không đủ để nâng cấp', { theme: 'light' });
                    }
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    };

    if (!Array.isArray(myMission.missions) || myMission.missions.length <= 0) return false;

    return (
        <div className="main-container">
            <div className="sc-AjmGg kRrDOZ">
                <div className="sc-hUpaCq fvrbNa">
                    <img src={banner3} alt="Banner" />
                </div>
                <div className="textAnimate">
                    <FontAwesomeIcon className="fa-arrow-left" icon={faBullhorn} />
                    <div className="wrapper">
                        <p className="target">
                            Chương trình khuyến mãi "Gian hàng ghép đôi" thưởng ngay $66 cho mỗi cặp đôi khi đăng ký và
                            mở thành công gian hàng ghép đôi. Vui lòng liên hệ dịch vụ CSKH để tìm hiểu thêm!
                        </p>
                    </div>
                </div>
                <div
                    className="search-order"
                    onClick={() => {
                        if (myMission.type === 2) {
                            setBox(true);
                            handAnimateMission();
                        } else {
                            setUpdate(true);
                        }
                    }}
                >
                    {!box ? (
                        <button type="button" className="ant-btn ant-btn-primary ant-btn-lg sc-lcepkR hJmUWK">
                            <span>Nhận đơn hàng</span>{' '}
                        </button>
                    ) : (
                        <div
                            className="ant-btn ant-btn-primary ant-btn-lg sc-lcepkR hJmUWK progress-bar-div"
                            style={{ backgroundColor: '#373b38' }}
                        >
                            <button type="button" disabled>
                                <div>
                                    <span style={{ opacity: '1' }}>Đang tìm kiếm đơn hàng...</span>
                                </div>
                            </button>
                            {/* <div className="progress-bar">
                                <div className="progress-bar-text">{`${progress}%`}</div>
                            </div> */}
                        </div>
                    )}
                </div>
                <div className="ant-row ant-row-space-between">
                    <div className="ant-col">
                        <div className="sc-ikJyIC gNkWet">
                            <div className="sc-jJoQJp hzrkvO">Số dư tài khoản</div>
                            <div className="sc-hiCibw iYoREV" style={{ color: 'rgb(231, 233, 236)' }}>
                                {formatter.format(user?.data[0].money)}
                            </div>
                            <div className="sc-jJoQJp hzrkvO">Thu nhập hôm nay</div>
                            <div className="sc-hiCibw iYoREV" style={{ color: 'rgb(82, 164, 120)' }}>
                                {formatter.format(user?.moneyEarn.moneyEarnToday)}
                            </div>
                        </div>
                    </div>

                    <div className="ant-col">
                        <div className="sc-ikJyIC gNkWet">
                            <div className="sc-jJoQJp hzrkvO">Số dư đang xử lý</div>
                            <div className="sc-hiCibw iYoREV" style={{ color: 'rgb(183, 62, 82)' }}>
                                {formatter.format(user?.data[0].dongbangtk)}
                            </div>
                            <div className="sc-jJoQJp hzrkvO">Thu nhập hôm qua</div>
                            <div className="sc-hiCibw iYoREV" style={{ color: 'rgb(160, 73, 211)' }}>
                                {formatter.format(user?.moneyEarn.moneyEarnPreDay)}
                            </div>
                        </div>
                    </div>

                    <div className="ant-col">
                        <div className="sc-ikJyIC gNkWet">
                            <div className="sc-jJoQJp hzrkvO">Gian hàng của bạn</div>
                            <div className="sc-hiCibw iYoREV" style={{ color: 'rgb(231, 233, 236)' }}>
                                {user?.data[0].name_level}
                            </div>
                            <div className="sc-jJoQJp hzrkvO">Mức vốn đầu tư</div>
                            <div className="sc-hiCibw iYoREV" style={{ color: 'rgb(82, 164, 120)' }}>
                                {formatter.format(Number(user?.data[0].price))}
                            </div>
                        </div>
                    </div>

                    <div className="ant-col">
                        <div className="sc-ikJyIC gNkWet">
                            <div className="sc-jJoQJp hzrkvO">Số đơn hoàn thành</div>
                            <div className="sc-hiCibw iYoREV" style={{ color: 'rgb(231, 233, 236)' }}>
                                {user?.mission?.amountToday}/{user.data[0].amount}
                            </div>
                            <div className="sc-jJoQJp hzrkvO">Lợi nhuận mỗi đơn</div>
                            <div className="sc-hiCibw iYoREV" style={{ color: 'rgb(82, 164, 120)' }}>
                                {Number((user?.data[0].roses).toFixed(1))}%
                            </div>
                        </div>
                    </div>
                </div>
                <div className="money-trade">MỨC VỐN ĐẦU TƯ</div>
                <div className="sc-clIzBv kHnHbZ box-home-shadow">
                    {level.map((item, i) => {
                        if (item.name_level === 'THÀNH VIÊN MỚI') return;
                        return (
                            <div className="sc-cxpSdN dNsaPg" key={i}>
                                <div className="dNsaPg-detail">
                                    <p>{item?.name_level}: </p>
                                    <span style={{ color: 'rgb(86, 177, 127)', marginLeft: '2px' }}>
                                        {' '}
                                        $ {normalFormatter.format(item?.price)}
                                    </span>
                                </div>
                                <div className="dNsaPg-detail">
                                    <p>Lợi nhuận: </p>
                                    <span style={{ color: 'rgb(86, 177, 127)', marginLeft: '2px' }}>
                                        {item?.roses.toFixed(1)}%
                                    </span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default Order;
