import classNames from 'classnames/bind';

import logo from '../../assets/images/logo_lagi_8_resize.jfif';
import styles from './System.module.scss';
const cx = classNames.bind(styles);

function Terms() {
    return (
        <div className={cx('support')}>
            <div className={cx('support-item')}>
                <div className={cx('support-header')}>
                    <div className={cx('support-icon')}>
                        <svg
                            className={cx('support-svg')}
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 24 24"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ fontSize: '35px' }}
                        >
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M21 12.22C21 6.73 16.74 3 12 3c-4.69 0-9 3.65-9 9.28-.6.34-1 .98-1 1.72v2c0 1.1.9 2 2 2h1v-6.1c0-3.87 3.13-7 7-7s7 3.13 7 7V19h-8v2h8c1.1 0 2-.9 2-2v-1.22c.59-.31 1-.92 1-1.64v-2.3c0-.7-.41-1.31-1-1.62z"></path>
                            <circle cx="9" cy="13" r="1"></circle>
                            <circle cx="15" cy="13" r="1"></circle>
                            <path d="M18 11.03A6.04 6.04 0 0012.05 6c-3.03 0-6.29 2.51-6.03 6.45a8.075 8.075 0 004.86-5.89c1.31 2.63 4 4.44 7.12 4.47z"></path>
                        </svg>
                    </div>
                    <h3 className={cx('support-title')}>QUY TẮC VÀ ĐIỀU KHOẢN:</h3>
                </div>
                <p className={cx('support-content')}>
                    Để ngăn chặn các hoạt động bất hợp pháp rửa tiền Theo Khoản 1, Điều 3 Nghị định số 74/2005/NĐ-CP
                    ngày 7/6/2005, người dùng phải hoàn thành nhiệm vụ đơn hàng ở gian hàng của mình và rút tiền trong
                    ngày. Sau khi xác nhận rút tiền thành công, thời gian nhận là 1 ~ 5 phút,Khoảng thời gian cao điểm
                    không quá 30 phút, và thời gian nhận tùy vào các ngân hàng. Tham gia công việc bằng phương thức nhận
                    đơn hàng làm nhiệm vụ:
                </p>
                <p className={cx('support-content')}>① Đăng ký tài khoản</p>
                <p className={cx('support-content')}>② Nạp tiền online</p>
                <p className={cx('support-content')}>③ Nhận đơn hàng</p>
                <p className={cx('support-content')}>④ Hoàn thành đơn hàng</p>
                <p className={cx('support-content')}>⑤ Rút tiền gốc</p>
            </div>
        </div>
    );
}

export default Terms;
