import classNames from 'classnames/bind';

import logo from '../../assets/images/logo_lagi_8_resize.jfif';
import styles from './System.module.scss';
const cx = classNames.bind(styles);

function Support() {
    return (
        <div className={cx('support')}>
            <div className={cx('support-item')}>
                <div className={cx('support-header')}>
                    <div className={cx('support-icon')}>
                        <svg
                            className={cx('support-svg')}
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 24 24"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ fontSize: '35px' }}
                        >
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M21 12.22C21 6.73 16.74 3 12 3c-4.69 0-9 3.65-9 9.28-.6.34-1 .98-1 1.72v2c0 1.1.9 2 2 2h1v-6.1c0-3.87 3.13-7 7-7s7 3.13 7 7V19h-8v2h8c1.1 0 2-.9 2-2v-1.22c.59-.31 1-.92 1-1.64v-2.3c0-.7-.41-1.31-1-1.62z"></path>
                            <circle cx="9" cy="13" r="1"></circle>
                            <circle cx="15" cy="13" r="1"></circle>
                            <path d="M18 11.03A6.04 6.04 0 0012.05 6c-3.03 0-6.29 2.51-6.03 6.45a8.075 8.075 0 004.86-5.89c1.31 2.63 4 4.44 7.12 4.47z"></path>
                        </svg>
                    </div>
                    <h3 className={cx('support-title')}>Giới thiệu về hệ thống COSTCO</h3>
                </div>
                <p className={cx('support-content')}>
                    Tập đoàn Costco hay còn được gọi là Costco Wholesale Corporation là một tập đoàn đa quốc gia của Mỹ
                    chuyên về các mảng bán lẻ và điều hành một chuỗi các câu lạc bộ bán buôn dành cho các thành viên.
                    Costco được thành lập vào ngày 15 tháng 9 năm 1983. Có trụ sở chính tại Washington, Hoa Kỳ. Tập đoàn
                    Costco quản lý trang web thương mại điện tử costtcoinc.store một website đấu giá trực tuyến, nơi mà
                    mọi người khắp nơi trên thế giới có thể mua hoặc bán hàng hóa và dịch vụ. Ngoài trụ sở tại Mỹ,
                    COSTCO còn có chi nhánh tại một số quốc gia khác. Tập đoàn COSTCO cũng sở hữu thương hiệu nổi tiếng
                    khác là Paypal.
                </p>
            </div>
        </div>
    );
}

export default Support;
