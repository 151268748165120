import styles from './Order.module.scss';
import classNames from 'classnames/bind';
import Header from '../../components/Layout/components/Header';
import SETTINGS from '../../setting.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import successIcon from '../../assets/images/success-icon-style.png';
import pendingIcon from '../../assets/images/pending-icon.png';
import ebayIconGuiHang from '../../assets/images/favicon.png';
import './History.css';
import { Link } from 'react-router-dom';

const axios = require('axios').default;
const cx = classNames.bind(styles);

function formateT(params) {
    let result = params < 10 ? '0' + params : params;
    return result;
}

function timerJoin2(params = '') {
    let date = '';
    if (params) {
        date = new Date(Number(params));
    } else {
        date = new Date();
    }
    let years = formateT(date.getFullYear());
    let months = formateT(date.getMonth() + 1);
    let days = formateT(date.getDate());

    let hours = formateT(date.getHours());
    let minutes = formateT(date.getMinutes());
    let seconds = formateT(date.getSeconds());
    return years + '-' + months + '-' + days + ' ' + hours + ':' + minutes + ':' + seconds;
}

function formatMoney(money = 0) {
    return String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

const checkToken = () => {
    let accessToken = localStorage.getItem('auth');
    if (!accessToken) {
        localStorage.removeItem('auth');
        window.location.href = '/account/login';
    } else {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/me`, {
                headers: {
                    'x-access-token': accessToken,
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'error') {
                    localStorage.removeItem('auth');
                    window.location.href = '/account/login';
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};

function History({ title }) {
    document.title = title;
    let [type_mission, setType] = useState('all');
    let [mission, setMission] = useState([]);

    useEffect(() => {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/mission/history`, {
                params: {
                    type: type_mission,
                },
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    setMission(data.data);
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    }, [type_mission]);

    useEffect(() => {
        checkToken();
    }, []);

    if (!Array.isArray(mission)) return false;

    return (
        <div className="history-order">
            <div className="header-order flex justify-between border-b-2">
                <div
                    onClick={() => setType('all')}
                    className={cx('history-progress', { 'px-[10px] py-[15px] text-[16px] font-medium': true })}
                    style={{ color: `${type_mission === 'all' ? '#ff3c61' : '#C4A6A7'}` }}
                >
                    Tất cả
                </div>
                <div
                    onClick={() => setType('pending')}
                    className={cx('history-progress', { 'px-[10px] py-[15px] text-[16px] font-medium': true })}
                    style={{ color: `${type_mission === 'pending' ? '#ff3c61' : '#C4A6A7'}` }}
                >
                    Chưa hoàn thành
                </div>
                <div
                    onClick={() => setType('success')}
                    className={cx('history-progress', { 'px-[10px] py-[15px] text-[16px] font-medium': true })}
                    style={{ color: `${type_mission === 'success' ? '#ff3c61' : '#C4A6A7'}` }}
                >
                    Thành công
                </div>
                <div
                    onClick={() => setType('fail')}
                    className={cx('history-progress', { 'px-[10px] py-[15px] text-[16px] font-medium': true })}
                    style={{ color: `${type_mission === 'fail' ? '#ff3c61' : '#C4A6A7'}` }}
                >
                    Thất bại
                </div>
            </div>
            <div className="content px-[12px] py-[17px]">
                <div className="list-items">
                    {mission.map((data, index) => {
                        return (
                            <div
                                key={index}
                                className="item-data relative p-[15px] mb-[15px]"
                                style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}
                            >
                                {data.status === 2 || (
                                    <img
                                        className="absolute w-[94px] h-[25px] right-[-4px] top-[-4px]"
                                        src={data.status === 0 ? pendingIcon : successIcon}
                                        alt=""
                                    />
                                )}
                                <div className="by flex">
                                    <img className="w-[19px] h-[19px]" src={ebayIconGuiHang} alt="COSTCO" />
                                    <span className="text-[#a6c4c3] text-xl ml-[5px]">Từ: COSTCO Shop</span>
                                </div>
                                <div className={cx('title', { 'text-[16px] py-[5px] font-semibold': true })}>
                                    {data.status === 0 ? (
                                        <Link to={`/order/confirm/${data.id_mission}`}>{data.name_mission}</Link>
                                    ) : (
                                        data.name_mission
                                    )}
                                    {/* {data.name_mission} */}
                                </div>
                                <div className="box-content">
                                    <div className="flex">
                                        <img className="w-[83px] h-[83px] rounded-lg" src={data.image} alt="" />
                                        <div className="px-[15px] flex-1">
                                            <div className="info flex justify-between">
                                                <div className="">
                                                    <p className="text-[#a6c4c3] text-xl">Giá trị đơn hàng</p>
                                                    <p className="text-[#0dc253] font-bold">
                                                        + {formatMoney(data.price.toFixed(2))}$
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-[#a6c4c3] text-xl">Lợi nhuận</p>
                                                    <p className="text-[#ffa900] font-bold">
                                                        + {formatMoney(data.receive.toFixed(2))}$
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="id-order mt-[5px]">
                                                <p className="text-[#a6c4c3] text-xl my-[2px]">
                                                    Mã đơn: {data.id_mission}
                                                </p>
                                            </div>
                                            <div className="text-[#a6c4c3] text-xl my-[2px]">
                                                Thời gian: {timerJoin2(data.time)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default History;
