import React, { createContext, useState, useContext, useEffect, useRef, useCallback } from 'react';
// import io from 'socket.io-client';
import SETTINGS from '../setting.json';

// const socket = io.connect(SETTINGS.BASE_URL_SOCKET, { path: '/mysocket' });

const StateContext = createContext();

const ContextProvider = ({ children }) => {
    const [reload, setReload] = useState(true);
    const socket = useRef(null);

    useEffect(() => {
        connectWebSocket();

        return () => {
            if (socket.current) {
                socket.current.close();
            }
        };
    }, []);

    function connectWebSocket() {
        socket.current = new WebSocket(SETTINGS.BASE_URL_SOCKET);

        socket.current.onclose = () => {
            setTimeout(() => connectWebSocket(), 1000);
        };
    }

    const sendWithdraw = useCallback((username, invite) => {
        let data = { username, invite };
        data = JSON.stringify(data);
        socket.current.send(data);
        socket.current.close();
    }, []);

    // const sendWithdraw = (username, invite) => {
    //     let data = { username, invite };
    //     data = JSON.stringify(data);
    //     ws.send(data);
    //     //console.log(username);
    // };
    // useEffect(() => {
    //     ws.current = new WebSocket("ws://localhost:8080");

    //     // Opening the ws connection

    //     ws.current.onopen = () => {
    //       console.log("Connection opened");
    //       setConnectionOpen(true);
    //     };

    //     // Listening on ws new added messages

    //     ws.current.onmessage = (event) => {
    //       const data = JSON.parse(event.data);
    //       setMessages((_messages) => [..._messages, data]);
    //     };

    //     return () => {
    //       console.log("Cleaning up...");
    //       ws.current.close();
    //     };
    //   }, []);

    return <StateContext.Provider value={{ reload, setReload, sendWithdraw }}>{children}</StateContext.Provider>;
};

export default ContextProvider;

export const useStateContext = () => useContext(StateContext);
